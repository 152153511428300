import React, { Component } from "react";

class Webdesign extends Component{
    render(){
        const {branstyle } = this.props;
        return(
            <React.Fragment>
                <ul className={`brand-list three-col last-col ${branstyle}`}>
                    <li>
                        <img src="/assets/images/technologies/opensource/php-svgrepo-com.svg" alt="php" width="80" height="80"/>
                    </li>
                    <li>
                        <img src="/assets/images/technologies/opensource/laravel-2.svg" alt="laravel" width="77" height="80"/>
                    </li>
                    <li>
                        <img src="/assets/images/technologies/opensource/nodejs-icon.svg" alt="nodejs" width="73" height="80"/>
                    </li>
                    <li>
                        <img src="/assets/images/technologies/opensource/codeigniter.svg" alt="codeigniter" width="67" height="80"/>
                    </li>
                    <li>
                        <img src="/assets/images/technologies/opensource/cakephp.svg" alt="cakephp" width="80" height="80"/>
                    </li>
                    <li>
                        <img src="/assets/images/technologies/opensource/yii.svg" alt="yii" width="75" height="80"/>
                    </li>
                </ul>
            </React.Fragment>
        )
    }
}
export default Webdesign;