import React, { Component , Fragment, useEffect, useState, useRef  } from "react";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import { FiHeadphones , FiMail , FiMapPin } from "react-icons/fi";
import { FaMapMarkerAlt } from "react-icons/fa";
import SliderOne from "../component/slider/SliderOne";
import AsNavFor from "../component/AsNavFor";
import ServiceTwo from "../elements/service/ServiceTwo";
import About from "../component/HomeLayout/homeOne/About";
import Portfolio from "../component/HomeLayout/homeOne/Portfolio";
import BrandTwo from "../elements/BrandTwo";
import Industries from "../elements/Industries";
import Helmet from "../component/common/Helmet";
import Particles from "react-tsparticles";
import { testimonialSlick } from "../../src/page-demo/script";
import Slider from "react-slick";

import { FaRegBuilding } from "react-icons/fa";
const SlideList = [

    {
        textPosition: 'text-center',
        category: '',
        title: 'Creative One Page',
        description: 'There are many variations of passages of Lorem Ipsum available but the majority have suffered alteration.',
        buttonText: 'Contact Us',
        buttonLink: '/contact'
    }
]




class MainDemo extends Component{

    
    render(){
        return(
            <Fragment> 
                <Helmet pageTitle="Main Demo" />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Slider Area   */}
                <div className="slider-activation slider-creative-agency with-particles position-relative" id="home">
                    <div className="frame-layout__particles">
                        <Particles className="particle"  
                            options={{
                                style:{
                                    position: "absolute"
                                },
                                fpsLimit: 100,
                                interactivity: {
                                detectsOn: "canvas",
                                events: {
                                    onClick: {
                                    enable: true,
                                    mode: "push",
                                    },
                                    onHover: {
                                    enable: true,
                                    mode: "repulse",
                                    },
                                    resize: true,
                                },
                                modes: {
                                    bubble: {
                                        distance: 100,
                                        duration: 2,
                                        opacity: 0.8,
                                        size: 10,
                                        color: "#888",
                                    },
                                    push: {
                                    quantity: 4,
                                    },
                                    repulse: {
                                        distance: 100,
                                        duration: 0.4,
                                        color: "#888",
                                    },
                                },
                                },
                                particles: {
                                color: {
                                    value: "#888",
                                },
                                links: {
                                    color: "#888",
                                    distance: 150,
                                    enable: true,
                                    opacity: 0.5,
                                    width: 1,
                                },
                                collisions: {
                                    enable: true,
                                },
                                move: {
                                    direction: "none",
                                    enable: true,
                                    outMode: "bounce",
                                    random: false,
                                    speed: 6,
                                    straight: false,
                                   
                                },
                                number: {
                                    density: {
                                        enable: true,
                                        value_area: 2000,
                                    },
                                    value: 80,
                                },
                                opacity: {
                                    value: 0.5,
                                },
                                shape: {
                                    type: "circle",
                                },
                                size: {
                                    random: true,
                                    value: 5,
                                },
                                },
                                detectRetina: true,
                            }}
                        />
                    </div>
                    <SliderOne />
                    
                </div>
                {/* End Slider Area   */}
                
                {/* Start About Area */}
                <div className="about-area about-position-top pb--120">
                    <About />
                </div>
                {/* End About Area */}

                {/* Start Brand Area */}
                <div className="rn-brand-area brand-separation bg_color--5 pt-0 pb--120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 section-title">
                                <BrandTwo /> 
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area */}


                {/* Start Service Area */}
                <div className="service-area ptb--80 theme-bg">
                   <div className="container">
                        <ServiceTwo />
                   </div>
                </div>
                {/* End Service Area */}

                {/* Start Portfolio Area */}
                 <div className="portfolio-area ptb--120 bg_color--1">
                    <div className="portfolio-sacousel-inner mb--55">
                        <Portfolio />
                    </div>
                </div> 
    
                {/* End Portfolio Area */}

                {/* Start Industries Area */}
                    <div className="rn-brand-area custom-script  theme-bg ptb--120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 section-title">
                                <Industries /> 
                            </div>
                        </div>
                    </div>
                    </div>
                {/* End Industries Area */}

                {/* Start Team Area  */}
                  <div className="rn-team-area bg_color--1 ptb--120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title service-style--3 text-center mb--25 team-intro">
                                    <p className="sub-heading-text">TEAMWORK BUILDS TRUST</p>
                                    <div className="title custom-heading h2">CREATIVE PEOPLE</div>                                    
                                    <p>Our leadership team is packed with expertise and dedicated to help your brand grow. We bring together industry-leading web designers, brilliant content creators, top digital marketers, and specialists in open source technology to deliver the digital products you've been waiting for. We do not work for you to fall in love with us. We do it so your customers will fall in love with you.* </p>
                                    <p className="bold-text">*This is a half-truth. You will also love it !!!</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            
                            {/* Start Single Team  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="team-wrap">
                                <div className="team">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/team/keyur-frame.jpg" alt="KEYUR PATEL" height="305" width="372" loading="lazy"/>
                                    </div>
                                    <div className="content">
                                        <div className="title h4">KEYUR PATEL</div>
                                        <p className="designation">CO-FOUNDER & CTO</p>
                                        <p className="designation team-skills">With great experience in multiple technologies, Keyur stands tall in terms of leveraging the in-house projects with innovation and technical proficiency. On the edge of his key expertise, we guarantee clients – the optimum satisfaction.
                                        </p>
                                    </div>
                                    <div className="text-center mt-3">
                                    <div className="title mb-0 h4">KEYUR PATEL</div>
                                    <p className="designation">CO-FOUNDER & CTO</p>
                                    </div>

                                </div>
                                </div>
                            </div>
                            {/* End Single Team  */}

                            {/* Start Single Team  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                            <div className="team-wrap">
                                <div className="team">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/team/nikhil-frame.jpg"  alt="NIKHIL JAKHOTIA" height="305" width="372" loading="lazy"/>
                                    </div>
                                    <div className="content">
                                        <div className="title h4">NIKHIL JAKHOTIA</div>
                                        <p className="designation">CO-FOUNDER & CEO</p>
                                        <p className="designation team-skills">From holding the Marketing reins of Big Balance Sheet brands to creating satisfaction for all customer contact points, Nikhil is well-versed in developing high-quality business strategies and plans. He always sparks emotional connections with a user-first approach.
                                        </p>
                                    </div>
                                    <div className="text-center mt-3">
                                    <div className="title mb-0 h4">NIKHIL JAKHOTIA</div>
                                        <p className="designation">CO-FOUNDER & CEO</p>
                                        </div>
      
                                </div>
                                </div>
                            </div>
                            {/* End Single Team  */}

                            {/* Start Single Team  */}
                             <div className="col-lg-4 col-md-6 col-sm-6 col-12 mx-auto">
                             <div className="team-wrap">
                                <div className="team">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/team/new-frame.jpg" alt="NAMRATA JAKHOTIA" height="305" width="372" loading="lazy"/>
                                    </div>
                                    <div className="content">
                                        <div className="title h4">NAMRATA JAKHOTIA</div>
                                        <p className="designation">CREATIVE DIRECTOR</p>
                                        <p className="designation team-skills">Namrata has been with Rigic since its beginning. She helps defining brands and creating the experiences. Her content strategies integrates the best fit with design.
                                        </p>
                                    </div>
                                    <div className="text-center mt-3">
                                    <div className="title mb-0 h4">NAMRATA JAKHOTIA</div>
                                        <p className="designation">CREATIVE DIRECTOR</p>
                                        </div>
                                    {/* <ul className="social-icon" >
                                        <li><a href="https://www.facebook.com/"><FaFacebookF /></a></li>
                                        <li><a href="http://linkedin.com/"><FaLinkedinIn /></a></li>
                                        <li><a href="https://twitter.com/"><FaTwitter /></a></li>
                                    </ul> */}
                                </div>
                             </div>
                            </div>
                            {/* End Single Team  */}

                        </div>
                    </div>
                  </div>
                {/* End Team Area  */}

                <div className="rn-testimonial-area   pb--120 testimonial-slider text-center">
                    <div className="container ">
                    <span className="sub-heading-text">WHAT PEOPLE SAY</span>
                    <div className="title custom-heading h2">TESTIMONIALS</div>
                        <Slider className="brand-slider" {...testimonialSlick}>
                            <div className="testimonial-content text-center">
                                <p>We gave the team a serious challenge on the size of the project and how long they had to build it. They achieved all the goals we set out. Great job. Already worked on multiple projects at the same time since long and getting exceptional results always. Go for them without a doubt!</p>
                                <div className="author-info">
                                    <div className="h5"><span>-GEORGE AFFLECK , </span>Curve Communication</div>
                                </div>
                            </div>
                            <div className="testimonial-content text-center">
                                <p>Best work I've found so far! I highly recommend Rigic Global Solutions and I will be using them for my online development in the future! My brand is 100x more visible and able to grow because of the well done and intuitive enhancements from RGS.</p>
                                <div className="author-info">
                                <div className="h5"><span>-Big Yen Music</span></div>
                                </div>
                                
                            </div>
                            <div className="testimonial-content text-center">
                                 <p>I had a wonderful experience working with Rigic Global Solutions. This team was efficient, helpful, and quick to respond. They were able to help me with the exact problems I wanted to fix and projects I wanted accomplished. They knew I was working on a deadline and made sure to work alongside me to get things done and make sure I was happy. I can highly recommend this team and would work with them in the future without hesitation.</p>   
                                 <div className="author-info">
                                 <div className="h5"><span>-Alex Jordan</span></div>
                                </div>
                            </div>
                            <div className="testimonial-content text-center">
                                 <p>Rigic &amp; team were able to quickly deliver high-quality code under a tight deadline. Communication and availability were excellent so we were able to revise and implement any necessary edits/changes efficiently and effectively so as not to delay the project timeline. We enjoyed working with them and hope to work on another project soon.</p> 
                                 <div className="author-info">
                                 <div className="h5"><span>-Jack Finio</span></div>
                                </div>  
                            </div>
                            <div className="testimonial-content text-center">
                                 <p>This is the second website that they have done for my business. And, again we are delighted. We will definitely return for any Wordpress sites we need. They are prompt, efficient and very easy to work with.</p>   
                                 <div className="author-info">
                                 <div className="h5"><span>-Sharifa Salaam</span></div>
                                </div>
                            </div>
                            <div className="testimonial-content text-center">
                                 <p>Professional, Great english, Very responsive. They are great.Team was there to explain and make sure we understand it all trough the process. Came with great ideas and help to improve the product.</p>  
                                 <div className="author-info">
                                 <div className="h5"><span>-The Pick App Market Place</span></div>
                                </div> 
                            </div>
                            <div className="testimonial-content text-center">
                                 <p>This job was completed with ease and in a very professional manner. Rigic Global Solutions was very helpful, timely and professional. They worked around my issues with ease and even had feedback to improve upon my website and brand visibility.</p>   
                                 <div className="author-info">
                                    <div className="h5"><span>-James Frolio</span></div>
                                </div>
                            </div>
                            <div className="testimonial-content text-center">
                                 <p>I strongly recommend to hire this company. They did very fabulous job for me. I am kindly a strong and hard person but so far I worked with this company, they did exactly what I required them to do.</p>   
                                 <div className="author-info">
                                    <div className="h5"><span>-John Brighton</span></div>
                                </div>
                            </div>
                            <div className="testimonial-content text-center">
                                 <p>I can't tell you how organized and on time they were with our project. I will use Rigic over and over. You can't go wrong with someone who communicates clearly and gets the job done as stated and quoted.</p>  
                                 <div className="author-info">
                                    <div className="h5"><span>-Boris Gonzalez</span></div>
                                </div> 
                            </div>
                        </Slider>
                    </div>
                </div>
                {/* End Testimonial Area */}

                {/* Start TECHNOLOGY & PROVIDERS  */}

                <div className="bg_color--1">
                <div className="title custom-heading h2 text-center mb-md-5 mb-3">TECHNOLOGY & PROVIDERS</div>
               
                
                {/* <TechnologyTab column="col-lg-4 col-md-6 col-sm-6 col-12" /> */}
                    <div className="container technologies-grid">                    
                        <AsNavFor/>
                    </div>


                 </div>
                {/* End TECHNOLOGY & PROVIDERS  */}


                {/* Start Contact Top Area  */}

                <div className="rn-contact-top-area ptb--120 contact-bottom-space">
                    <div className="container">
                    <div className="custom-heading title h2 text-center mb-5">CONTACT US</div>
                        <div className="row">

                            {/* Start Single Address  */}
                            <div className="col-lg-6 col-md-6 col-sm-6 col-12 pb-md-5 mb-4 mb-md-3">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiHeadphones />
                                    </div>
                                    <div className="inner">
                                        <div className="title text-uppercase h4">Phone Number</div>
                                        <p><a  aria-label="contact-info" href="tel:+919737143243">+91 973-714-3243</a></p>
                                        <p><a  aria-label="call-info" href="tel:+919638464855">+91 963-846-4855</a></p>
                                         
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                            {/* Start Single Address  */}
                            <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt_mobile--50 pb-md-5 mb-4 mb-md-3">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMail />
                                    </div>
                                    <div className="inner">
                                        <div className="title h4 text-uppercase">Email Address</div>
                                        <p><a  aria-label="email-info" href="mailto:info@rigicglobalsolutions.com">info@rigicglobalsolutions.com</a></p>
                                        
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                            {/* Start Single Address  */}
                            <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt_md--50 mt_sm--50 pb-md-5 mb-4 mb-md-3">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FaRegBuilding />
                                    </div>
                                    <div className="inner">
                                        <div className="title h4">HEAD OFFICE</div>
                                        <p>T.F. 307, i Square, Nr Shukan Mall X <br /> Roads, Science City,Ahmedabad-380060,  <br/>Gujarat, India.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt_md--50 mt_sm--50 pb-md-5 mb-4 mb-md-3">
                                <div className="rn-address">
                                    <div className="icon">
                                    <FaMapMarkerAlt />
                                    </div>
                                    <div className="inner">
                                        <div className="title h4">BRANCH OFFICE</div>
                                        <p>A-404, Monarch, Gaurav Path<br /> Road, Pal, Surat-395009, <br/>Gujarat, India.</p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                        </div>
                    </div>
                </div>
                {/* End Contact Top Area  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />
            </Fragment>
        )
    }
}
export default MainDemo;