import React, { Component } from "react";

class Webdesign extends Component{
    render(){
        const {branstyle } = this.props;
        return(
            <React.Fragment>
                <ul className={`brand-list three-col last-col ${branstyle}`}>
                    <li>
                        <img className="small-logo" src="/assets/images/technologies/project/monday-1.svg" alt="monday" width="89" height="55"/>
                    </li>
                    <li>
                        <img className="small-logo" src="/assets/images/technologies/project/slack-new-logo.svg" alt="slack" width="55" height="55"/>
                    </li>
                    <li>
                        <img src="/assets/images/technologies/project/trello-logo.svg" alt="trello" width="150" height="43"/>
                    </li>
                    <li>
                        <img className="small-logo" src="/assets/images/technologies/project/jira-1.svg" alt="jira" width="55" height="55"/>
                    </li>
                    <li>
                        <img src="/assets/images/technologies/project/basecamp-logo-2019.svg" alt="basecamp" width="150" height="30"/>
                    </li>
                </ul>
            </React.Fragment>
        )
    }
}
export default Webdesign;