import React, { Component } from "react";

class Webdesign extends Component{
    render(){
        const {branstyle } = this.props;
        return(
            <React.Fragment>
                <ul className={`brand-list ${branstyle}`}>
                    <li>
                        <img src="/assets/images/technologies/microsoft/c--4.svg" alt="c" width="71" height="80"/>  
                    </li>
                    <li>
                        <img src="/assets/images/technologies/microsoft/aspnet-svgrepo-com.svg" alt="aspnet" width="80" height="80"/>
                    </li>
                </ul>
            </React.Fragment>
        )
    }
}
export default Webdesign;