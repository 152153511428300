import React, { Component } from "react";

class Webdesign extends Component{
    render(){
        const {branstyle } = this.props;
        return(
            <React.Fragment>
                <ul className={`brand-list three-col ${branstyle}`}>
                    <li>
                        <img src="/assets/images/technologies/database/mysql-logo-svgrepo-com.svg" alt="mysql" width="80" height="80"/>
                    </li>
                    <li>
                        <img src="/assets/images/technologies/database/mariadb.svg" alt="mariadb"  width="120" height="80"/>
                    </li>
                    <li>
                        <img src="/assets/images/technologies/database/mongodb-svgrepo-com.svg" alt="mongodb"  width="80" height="80"/>
                    </li>
                    <li>
                        <img src="/assets/images/technologies/database/microsoft-sql-server-1.svg" alt="microsoft"  width="125" height="80"/>
                    </li>
                </ul>
            </React.Fragment>
        )
    }
}
export default Webdesign;