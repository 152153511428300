import React, { Component } from "react";

class Webdesign extends Component{
    render(){
        const {branstyle } = this.props;
        return(
            <React.Fragment>
                <ul className={`brand-list three-col last-col ${branstyle}`}>
                    <li>
                        <img className="small-logo" src="/assets/images/technologies/payment/stripe-4.svg" alt="stripe" width="132" height="55"/>  
                    </li>
                    <li>
                        <img src="/assets/images/technologies/payment/paypal-3.svg" alt="stripe" width="150" height="40"/>
                    </li>
                    <li>
                        <img src="/assets/images/technologies/payment/dot-net-svgrepo-com.svg" alt="stripe" width="80" height="80"/>
                    </li>
                    <li>
                        <img src="/assets/images/technologies/payment/payment-square-svgrepo-com.svg" alt="payment" width="80" height="80"/>
                    </li>
                    <li>
                        <img src="/assets/images/technologies/payment/Braintree_(company)-Logo.wine.svg" alt="Braintree_" width="120" height="80"/>
                    </li>
                    <li>
                        <img src="/assets/images/technologies/payment/razorpay.svg" alt="razorpay" width="150" height="32"/>
                    </li>
                </ul>
            </React.Fragment>
        )
    }
}
export default Webdesign;