import React, { Component } from "react";

class Webdesign extends Component{
    render(){
        const {branstyle } = this.props;
        return(
            <React.Fragment>
                <ul className={`brand-list three-col last-col ${branstyle}`}>
                    <li>
                        <img className="" src="/assets/images/technologies/frontend/html5-2.svg" alt="html5" width="57" height="80"/>
                    </li>
                    <li>
                        <img className="" src="/assets/images/technologies/frontend/css-3-logo-svgrepo-com.svg" alt="css" width="80" height="80"/>
                    </li>
                    <li>
                        <img className="small-logo" src="/assets/images/technologies/frontend/bootstrap.png" alt="bootstrap" width="69" height="55"/>
                    </li>
                    <li>
                        <img className="small-logo" src="/assets/images/technologies/frontend/vue-js-icon.svg" alt="vue" width="64" height="55"/>
                    </li>
                    <li>
                        <img className="small-logo" src="/assets/images/technologies/frontend/jquery-1.svg" alt="jquery" width="150" height="34"/>
                    </li>
                    <li>
                        <img className="small-logo" src="/assets/images/technologies/frontend/React-icon.svg.png" alt="React" width="63" height="55"/>
                    </li>
                    <li>
                        <img className="" src="/assets/images/technologies/frontend/angular-icon.svg" alt="angular" width="74" height="80"/>
                    </li>
                    <li>
                        <img className="small-logo" src="/assets/images/technologies/frontend/tailwind-css-icon.svg" alt="tailwind" width="92" height="55"/>
                    </li>

                </ul>
            </React.Fragment>
        )
    }
}
export default Webdesign;