import React, { Component } from "react";

class Webdesign extends Component{
    render(){
        const {branstyle } = this.props;
        return(
            <React.Fragment>
                <ul className={`brand-list three-col last-col ${branstyle}`}>
                    <li>
                        <img className="small-logo" src="/assets/images/technologies/mobileapplication/apple-ios.svg" alt="apple" width="86" height="55"/>
                    </li>
                    <li>
                        <img src="/assets/images/technologies/mobileapplication/android-svgrepo-com.svg" alt="android" width="80" height="80"/>
                    </li>
                    <li>
                        <img src="/assets/images/technologies/mobileapplication/flutter-svgrepo-com.svg" alt="flutter" width="80" height="80"/>
                    </li>
                    <li>
                        <img src="/assets/images/technologies/mobileapplication/react-native-1.svg" alt="react" width="100" height="80"/>
                    </li>
                </ul>
            </React.Fragment>
        )
    }
}
export default Webdesign;