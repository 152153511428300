import React, { Component } from "react";

class Webdesign extends Component{
    render(){
        const {branstyle } = this.props;
        return(
            <React.Fragment>
                <ul className={`brand-list ${branstyle}`}>
                    <li>
                        <img src="/assets/images/technologies/code/github-icon-1-logo-svgrepo-com.svg" alt="github" width="80" height="80"/>    
                    </li>
                    <li>
                        <img src="/assets/images/technologies/code/bitbucket-svgrepo-com.svg" alt="bitbucket" width="80" height="80"/>
                    </li>
                </ul>
            </React.Fragment>
        )
    }
}
export default Webdesign;