import React, { Component } from "react";
import {Link} from "react-router-dom";

const PortfolioListContent = [
    {
        image: 'image-2',
        bigImage: '/assets/images/portfolio/projects/biocoiff.png',
        category: 'E-Commerce',
        title: 'Biocoiff',
        link:"http://biocoiff.com/"
    },
    {
        image: 'image-3',
        bigImage: '/assets/images/portfolio/projects/codeblue.png',
        category: 'Technology',
        title: 'Codeblue',
        link:"https://codeblue.ventures/",

    },

    {
        image: 'image-5',
        bigImage: '/assets/images/portfolio/projects/vaniac.png',
        category: 'Law',
        title: ' Vancouver International Arbitration',
        link:"https://vaniac.org/"
    },
    {
        image: 'image-6',
        bigImage: '/assets/images/portfolio/projects/mbstryve.png',
        category: 'Web Agency',
        title: 'mbstryve',
        link:"https://rigicgspl.com/mbstryve/#"
    },
    {
        image: 'image-7',
        bigImage: '/assets/images/portfolio/projects/biocoiff-pro.png',
        category: 'E-Commerce',
        title: 'Biocoiff Pro',
        link:"https://www.biocoiff-pro.com/"
    },
    {
        image: 'image-8',
        bigImage: '/assets/images/portfolio/projects/willowpayments.png',
        category: 'Technology',
        title: 'Willow',
        link:"https://willowpayments.com/"
    },
    {
        image: 'image-9',
        bigImage: '/assets/images/portfolio/projects/ielts.png',
        category: 'Education',
        title: 'IELTS Global Tutor',
        link:"https://www.ieltsglobaltutor.com/"
    },
    {
        image: 'image',
        bigImage: '/assets/images/portfolio/projects/popnyard.png',
        category: 'E-Commerce',
        title: "Pop'n Yardy Party",
        link:"https://popnyardyparty.com/"
    },
    {
        image: 'image',
        bigImage: '/assets/images/portfolio/projects/edminify.png',
        category: 'Software',
        title: 'Edminify',
        link:"https://edminify.com/"
    },
    {
        image: 'image',
        bigImage: '/assets/images/portfolio/projects/sfox.png',
        category: 'Business',
        title: 'Sfox',
        link:"https://www.sfox.com/"
    },
    {
        image: 'image',
        bigImage: '/assets/images/portfolio/projects/studinthome.png',
        category: 'Real Estate',
        title: 'Studint',
        link:"https://studinthome.com/"
    },
    {
        image: 'image',
        bigImage: '/assets/images/portfolio/projects/twd.png',
        category: 'Training',
        title: 'TWD'
    },
    {
        image: 'image',
        bigImage: '/assets/images/portfolio/projects/bigyenmusic.png',
        category: 'Entertainment',
        title: 'Bigyen',
        link:"https://www.trainwithdanny.com/"
    },
    {
        image: 'image',
        bigImage: '/assets/images/portfolio/projects/eastvalueresearch.png',
        category: 'Software',
        title: 'East Value Research',
        link:"https://eastvalueresearch.com/"
    },
    {
        image: 'image',
        bigImage: '/assets/images/portfolio/projects/createamotive.png',
        category: 'Technology',
        title: 'create.a.motive',
        link:"https://createamotive.com/"
    },
    {
        image: 'image',
        bigImage: '/assets/images/portfolio/projects/mycashreward.png',
        category: 'E-Commerce',
        title: 'MyCash Reward',
        link:"https://mycashreward.co.uk/"
    },
    {
        image: 'image',
        bigImage: '/assets/images/portfolio/projects/mantechenterprises.png',
        category: 'Technology',
        title: 'Manufacturing Technology',
        link:"https://mantechenterprises.com/"
    },
    {
        image: 'image',
        bigImage: '/assets/images/portfolio/projects/saintpius.png',
        category: 'Education',
        title: 'Saint Pius',
        link:"https://www.saintpius.ca/"
    },
    {
        image: 'image',
        bigImage: '/assets/images/portfolio/projects/lansdowne.png',
        category: 'Business',
        title: 'Lansdowne Centre',
        link:"https://lansdowne-centre.com/"
    },
    {
        image: 'image',
        bigImage: '/assets/images/portfolio/projects/travelindustrysolutions.png',
        category: 'Travel Industry Solutions',
        title: 'Travel',
        link:"https://travelindustrysolutions.com/"
    },

    {
        image: 'image',
        bigImage: '/assets/images/portfolio/projects/pousadasofportugal.png',
        category: 'PARA Promotions',
        title: 'Hotel',
        link:"https://www.pousadasofportugal.com/"
    },
    {
        image: 'image',
        bigImage: '/assets/images/portfolio/projects/kidswonder.png',
        category: 'Education',
        title: 'Kids Wonder',
        link:"https://www.kidswonder.net/"
    },
    {
        image: 'image',
        bigImage: '/assets/images/portfolio/projects/pixelcocreative.png',
        category: 'Web Agency',
        title: 'Pixelco',
        link:"https://pixelcocreative.com.au/"
    },
    {
        image: 'image',
        bigImage: '/assets/images/portfolio/projects/publicimagemgmt.png',
        category: 'Entertainment',
        title: 'Public Image Management'
    },
    {
        image: 'image',
        bigImage: '/assets/images/portfolio/projects/sunshinesolves.png',
        category: 'Web Agency',
        title: 'Sunshine Solution',
        link:"https://sunshinesolves.com/"
    },
    
    {
        image: 'image',
        bigImage: '/assets/images/portfolio/projects/alexmariejordan.png',
        category: 'Entertainment',
        title: 'Alex Marie Jordan',
        link:"https://www.alexmariejordan.com/"
    },
    {
        image: 'image',
        bigImage: '/assets/images/portfolio/projects/blackwomengunowners.png',
        category: 'Trainning',
        title: 'Black Women Gun Owners',
        link:""
    },
    {
        image: 'image',
        bigImage: '/assets/images/portfolio/projects/seymourhealth.png',
        category: 'Hospital',
        title: 'Seymour Health Center',
        link:"https://www.seymourhealth.ca/"
    },
    
    {
        image: 'image',
        bigImage: '/assets/images/portfolio/projects/freshnestla.png',
        category: 'Food',
        title: 'Fresh Nest',
        link:"https://www.freshnestla.com/"
    },
    
    {
        image: 'image',
        bigImage: '/assets/images/portfolio/projects/carecube.png',
        category: 'Hospital',
        title: 'Carecube',
        link:"https://carecube.clinic/"
    },
    
    {
        image: 'image',
        bigImage: '/assets/images/portfolio/projects/mallowayvillage.png',
        category: 'Real Estate',
        title: 'Malloway Village',
        link:"https://mallowayvillage.ca/"
    },
    {
        image: 'image',
        bigImage: '/assets/images/portfolio/projects/menchies.png',
        category: 'Food',
        title: 'Menchies Frozen Yogurt',
        link:"https://www.menchies.com/"
    },
    
    {
        image: 'image',
        bigImage: '/assets/images/portfolio/projects/prettygrafik.png',
        category: 'E-Commerce',
        title: 'Pretty Grafik',
        link:"https://prettygrafik.com/store/"
    },
    {
        image: 'image',
        bigImage: '/assets/images/portfolio/projects/mobilelace.png',
        category: 'Education',
        title: 'Mobilelace',
        link:"https://mobilelace.com/"
    },
   
    {
        image: 'image',
        bigImage: '/assets/images/portfolio/projects/swarmdigital.png',
        category: 'Web Agency',
        title: 'Swarm Digital Marketing',
        link:"https://www.swarmdigital.io/"
    },
    
    {
        image: 'image',
        bigImage: '/assets/images/portfolio/projects/cncmasters.png',
        category: 'Manufacturer',
        title: 'CNC Masters',
        link:"https://www.cncmasters.com/"
    },
    {
        image: 'image',
        bigImage: '/assets/images/portfolio/projects/vinnyrusso.png',
        category: 'Trainning',
        title: 'Vinnyrusso',
        link:"https://vinnyrusso.com/"
    },
    {
        image: 'image',
        bigImage: '/assets/images/portfolio/projects/thejeremybowers.png',
        category: 'Real Estate',
        title: 'Philadelphia Real Estate',
        link:"https://thejeremybowers.com/"
    },
    {
        image: 'image',
        bigImage: '/assets/images/portfolio/projects/solcreative.png',
        category: 'Web Agency',
        title: 'Sol Creative',
        link:"https://solcreative.ca/"
    },
    {
        image: 'image',
        bigImage: '/assets/images/portfolio/projects/housename.png',
        category: 'Real Estate',
        title: 'HouseName',
        link:"https://housename.ca/"
    },

]

class WebdesignPortfolioList extends Component{
    render(){
        const {column , styevariation } = this.props;
        const list = PortfolioListContent.slice(0 , this.props.item);
        return(
            <React.Fragment> 
                {list.map((value , index) => (
                    <div className={`${column}`} key={index}>
                        <div className={`portfolio ${styevariation}`}>
                            <div className="thumbnail-inner">
                                <div className={`thumbnail`} style={{backgroundImage:`url("${value.bigImage}")`}}>                                  
                                </div>
                            </div>
                            <div className="content">
                                <div className="inner">
                                    <p>{value.category}</p>
                                    <div className="title h4">{value.title}</div>
                                    <div className="portfolio-button">
                                        <a aria-label="portfolio-link" rel="nofollow"  target="_blank" href={value.link} className="rn-btn">View Details</a>
                                    </div>
                                </div>
                            </div>
                            <a aria-label="portfolio-link" rel="nofollow"  target="_blank" href={value.link} className="link-overlay"></a>
                        </div>
                    </div>
                ))}
               
            </React.Fragment>
        )
    }
}
export default WebdesignPortfolioList;