import React, { Component } from "react";

const ServiceList = [
    {
        icon: '01',
        title: 'Innovative Website Design',
        description: 'Our designers create websites that showcase your brand and intrigue your customers. With all the functionality and responsiveness you need.'
    },
    {
        icon: '02',
        title: 'Web Development',
        description: 'Our exceptional team delivers robust websites that do what you need them to do while offering your customers seamless interactivity and extreme ease of use.'
    },
    {
        icon: '03',
        title: 'E-Commerce Solutions',
        description: 'We help your products shine and make it easy for your customers to find what they want and finish their purchases. With easy and admin friendly customization.'
    },
]

class ServiceOne extends Component{
    render(){
        return(
            <React.Fragment>
                <div className="row">
                    {ServiceList.map( (val , i) => (
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                            <div className="service service__style--1">
                                <div className="icon">
                                    <img src={`/assets/images/icons/icon-${val.icon}.svg`} alt="Digital Agency" loading="lazy"/>
                                </div>
                                <div className="content">
                                    <div className="title h2 sec-title">{val.title}</div>
                                    <p>{val.description}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceOne;