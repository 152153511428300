import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import Accordion from 'react-bootstrap/Accordion';

const AnyReactComponent = ({ text }) => <div>{text}</div>;

class Privacy extends Component{
    static defaultProps = {
        center: {
            lat: 59.95,
            lng: 30.33
        },
        zoom: 11
    };

    render(){
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Privacy' />
                
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />


                <div className="pt-5 pb--120">
                    <div className="container pt--120">
                        <div className="row">
                            <div className="col-12 custom-accordion">
                                <h2 className="text-center custom-heading mb-4">PRIVACY POLICY</h2>
                                <p>Any information you place on Rigic Global Solutions Private Limited website is treated with the utmost care.</p>
                                <p>Certain information including your IP address, domain name is collected through cookies and similar technologies for a limited purpose. This is to learn which parts of our site you are visiting.This is with purpose to improve user experience next time you visit our site. Only statistics are captured to make the web site more informative and useful to the visitors.</p>
                                <p>Our site’s inquiry form requires you to give us contact information. We may use this contact information to send you information about our company services and the continuous upgrades in our service offerings.</p>
                                <p>We value your privacy and follow a strict “no-spam” policy! We never give, lease, sell or otherwise disclose your personal information, except under court orders.</p>

                            </div>
                        </div>
                    </div>
                </div>

          
                
                <Footer/>
                
            </React.Fragment>
        )
    }
}
export default Privacy