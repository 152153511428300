import React, { useEffect, useRef} from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import CustomphpPortfolioList from "../elements/portfolio/CustomphpPortfolioList";
import { Link } from "react-router-dom";






const PortfolioCustomphp = () => {
    const customPhp = useRef(null);
    // useEffect(() => {
    //     setTimeout(() => {
            
    //     window.scrollTo({
    //         top: customPhp.current.offsetTop - 100,
            
            
    //       })
    //     }, "0");
    //   }, [])
    return (
        <>
            <PageHelmet pageTitle='Counters' />

            {/* Start Header Area  */}
            <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
            {/* End Header Area  */}
            
            {/* Start Breadcrump Area */}
            <Breadcrumb title={'Portfolio'} bg_image={"bg_image--5"}    />
            {/* End Breadcrump Area */}

            {/* Start Page Wrapper  */}
            <main className="page-wrapper">




                {/* Start Portfolio Area */}
                <div className="portfolio-area ptb--120 " >
                    <div className="portfolio-sacousel-inner">
                    <div id="portfolio"></div>
                        <div className="container">
                            <div className="row text-center">
                                <div className="col-lg-12">
                                    <div className="section-title text-center service-style--3 mb--30">
                                        <div className="title custom-heading h2">DISCOVERING SPECIAL PEOPLE.</div>
                                        <div className="title custom-heading h2">PRODUCING EXTRAORDINARY PORTFOLIO</div>
                                        <p className="sub-heading-text">Choose your preference to start a project with us.</p>
                                    </div>

                                    
                                </div>
                            </div>

                             <div className="row text-center portfolio-row" ref={customPhp}>
                                <div className="col-lg-12">
                                    <div className="portfolio-custom-menu">
                                        <div className="tablist-inner">
                                            <ul className='pv-tab-button text-center mt--0'>
                                            <li><Link aria-label="all"  to="/portfolio#all"><span>All</span></Link></li>
                                                <li><Link aria-label="wordpress" to="/portfolio/wordpress#wordpress"><span>Wordpress</span></Link></li>
                                                <li><Link aria-label="ecommerce" to="/portfolio/ecommerce#ecommerce"><span>E-Commerce</span></Link></li>
                                                <li><Link aria-label="webdesign" to="/portfolio/webdesign#webdesign"><span>Web Design</span></Link></li>
                                                <li id="customphp" className="active"><Link aria-label="php" to="/portfolio/customphp#customphp"><span>Custom PHP</span></Link></li>
                                            </ul>
                                        </div>
                                     </div>
                                </div>

                                
                                    <CustomphpPortfolioList styevariation="text-center mt--40" column="col-lg-4 col-md-6 col-sm-6 col-12" item="100" />
                               
                             </div>



                         
                        </div>
                    </div>
                </div>
                {/* End Portfolio Area */}

                

            </main>
            {/* End Page Wrapper  */}

            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}
            
            {/* Start Footer Area  */}
            <Footer />
            {/* End Footer Area  */}

        </>
        
    )
}

export default PortfolioCustomphp;