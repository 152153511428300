import React, { Component } from "react";

class Webdesign extends Component{
    render(){
        const {branstyle } = this.props;
        return(
            <React.Fragment>
                <ul className={`brand-list three-col last-col ${branstyle}`}>
                    <li>
                        <img src="/assets/images/technologies/webdesign/figma.svg" alt="figma" width="80" height="80"/>
                    </li>
                    <li>
                        <img src="/assets/images/technologies/webdesign/photoshop.svg" alt="photoshop" width="80" height="80"/>
                    </li>
                    <li>
                        <img src="/assets/images/technologies/webdesign/adobe-xd.svg" alt="adobe" width="80" height="80"/>
                    </li>
                    <li>
                        <img src="/assets/images/technologies/webdesign/Adobe_Illustrator.png" alt="Adobe_Illustrator" width="80" height="80"/>
                    </li>
                    <li>
                        <img src="/assets/images/technologies/webdesign/canva-1.svg" alt="canva" width="80" height="80"/>
                    </li>
                    <li>
                        <img src="/assets/images/technologies/webdesign/invision.svg" alt="invision" width="80" height="80"/>
                    </li>
                </ul>
            </React.Fragment>
        )
    }
}
export default Webdesign;