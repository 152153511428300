import React, { Component } from "react";

class Webdesign extends Component{
    render(){
        const {branstyle } = this.props;
        return(
            <React.Fragment>
                <ul className={`brand-list three-col ${branstyle}`}>
                    <li>
                        <img src="/assets/images/technologies/cloud/amazon-web-services.svg" alt="amazon" width="150" height="56"/>
                    </li>
                    <li>
                        <img src="/assets/images/technologies/cloud/google-cloud-3.svg" alt="google cloud" width="150" height="26"/>
                    </li>
                    <li>
                        <img src="/assets/images/technologies/cloud/digitalocean.svg" alt="digitalocean" width="125" height="80"/>
                    </li>
                    <li>
                        <img src="/assets/images/technologies/cloud/azure-icon-svgrepo-com.svg" alt="azure" width="80" height="80"/>
                    </li>
                </ul>
            </React.Fragment>
        )
    }
}
export default Webdesign;