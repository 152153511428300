import React, { Component } from "react";

class Webdesign extends Component{
    render(){
        const {branstyle } = this.props;
        return(
            <React.Fragment>
                <ul className={`brand-list three-col last-col ${branstyle}`}>
                    <li>
                        <img className="small-logo" src="/assets/images/technologies/ecommerece/woocommerce.svg" alt="woocommerce"  width="92" height="55" />
                    </li>
                    <li>
                        <img src="/assets/images/technologies/ecommerece/shopify-2.svg" alt="shopify" width="150" height="43" />
                    </li>
                    <li>
                        <img src="/assets/images/technologies/ecommerece/opencart-svgrepo-com.svg" alt="opencart" width="80" height="80"/>
                    </li>
                    <li>
                        <img className="small-logo" src="/assets/images/technologies/ecommerece/magento-2.svg" alt="magento" width="46" height="55"/>
                    </li>
                    <li>
                        <img src="/assets/images/technologies/ecommerece/bigcommerce-1.svg" alt="bigcommerce" width="80" height="80"/>
                    </li>
                    <li>
                        <img src="/assets/images/technologies/ecommerece/prestashop.svg" alt="prestashop" width="80" height="80"/>
                    </li>
                </ul>
            </React.Fragment>
        )
    }
}
export default Webdesign;